import * as React from 'react';
import {NumberParam, StringParam, useQueryParams} from "use-query-params";
import {Typography} from "@material-ui/core";
import {Pagination, Skeleton} from "@material-ui/lab";
import {useSearchCards} from "./hooks";
import {CardGridList} from "../cards/CardGridList";
import {CardGridListItem} from "../cards/CardGridListItem";

/*
We're managing search filter state in the URL to provide browser history and shareable URLs
 */
export const SearchResults = ({cardListOptions}) => {
    const [query, setQuery] = useQueryParams({
        g: StringParam,
        l: StringParam,
        page: NumberParam,
        q: StringParam,
        s: NumberParam,
        se: NumberParam,
    });
    const {isLoading, isError, error, data} = useSearchCards(query);
    const handlePageChange = (event, value) => {
        if (value !== query.page) {
            setQuery({page: value});
        }
    };

    if (isLoading) { return <Skeleton animation="pulse" height={400} variant="rect" /> }
    if (isError) { return <Typography style={{color: `red`}}>Failed loading: {error.message}</Typography> }

    return (
        <>
            <Typography className="pb-4">Total results: {data.meta.filter_count}</Typography>
            {data.data && (
                <CardGridList>
                    {data.data.map(card => <CardGridListItem card={card} key={card.id} {...cardListOptions} />)}
                </CardGridList>
            )}
            <Pagination className="mt-4" count={data.meta.page_count} onChange={handlePageChange} page={query.page} />
        </>
    )
};
